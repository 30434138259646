*{
  margin:0;
  padding:0;
  box-sizing: border-box;
}
.main{
  width: 100vw;
  padding-top: 50px;
  background-color: rgb(192, 188, 188);
  display: flex;
  place-content: center;
  flex-direction:column;
  padding: 5%;
}

*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}