.teamLabels{
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
    width: 80%;
    align-self: flex-start;
    margin-left: 10px;
    
}
.teamInput{
    font-size: 20px;
    font-weight: bold;
    width: 100%;
  
    height: 35px;
    padding: 5px 10px;
}
.team-form{
    width: 80%;
    margin:0 auto;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.team-infos{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
    grid-template-rows: 1fr 1fr 1fr 200px;
    grid-column-gap: 20px;
}
.team-motivation{
    grid-column: 1/4;
    margin:0;
    }
    .team-motivation textarea{
  
    height: 100px;
    margin-bottom: 20px;
    }

.team-detail{
    display: flex;
    flex-direction: column;
}
.pilot-nr{
    font-size: 30px;
    font-weight: bold;
    margin: 20px 0;
}
.pilot-form{
    display: flex;
    width: 80%;
    margin:0 auto;
    flex-direction: column;
}
.pilot-form-container{
    display: flex;
    width: 90%;
    margin:0 auto;
    flex-direction: column;
    align-items: center;
}
.addPilotContainer{
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.addPilot{
    width: 180px;
    padding: 10px 0;
    font-size: 18px;
    justify-self: end;
    grid-row: 1/2;
}
.addTeam{
    width: 180px;
    padding: 10px 0;
    font-size: 18px;
}
.removePilot{
    width: 180px;
    padding: 10px 0;
    font-size: 18px;
    grid-row: 1/2;
    justify-self: start;
}
.buttonsContainer{
    width: 100%;
    padding: 10px 0;
    display: grid;
    
}
.confirmPilot{
    width: 180px;
    padding:10px 0;
    font-size: 18px;
    align-self: center;
}