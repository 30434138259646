.form-date{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 30%;
    background-color: #fff;
    -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0); 
    box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
   
    padding: 20px 0;
    align-self: center;
}
.labelDate{
    padding: 10px 10px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}
.inputDate{
    margin: 10px 0;
    padding : 5px 0 ;
    font-size: 20px;
    font-weight: bold;
}
.submitDate{
    margin:10px 10px;
    padding: 5px 45px;
    font-size: 18px;
    background-color:greenyellow;
    border: 0;
    cursor: pointer;
    transition: .2s all ease-in-out;
}
.submitDate:hover{
    font-size: 20px;
}